:root {
  --bg-color: #242424;
  --primary-color-hsl: 182 70% 50%;
  --primary-text-bg: linear-gradient(180deg, hsl(var(--primary-color-hsl) / 50%), hsl(var(--primary-color-hsl)));
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #303030;
}

html {
  font-size: 16px;
  font-size: min(max(1rem, 4vw), 22px);
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282828;
  z-index: 1000;

  .mini-logo {
    width: 82px;
    height: 82px;
    position: relative;
    border: 10px solid #282828;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      box-shadow: -4px -4px 10px rgba(67, 67, 67, 0.5),
        inset 4px 4px 10px rgba(0, 0, 0, 0.5),
        inset -4px -4px 10px rgba(67, 67, 67, 0.5),
        4px 4px 10px rgba(0, 0, 0, 0.3);
      animation-name: waves;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-duration: 1.8s;
    }

    img {
      width: 100%;
    }
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 1rem;
  background-color: transparent;
  z-index: 999;
  filter: invert(0.85);
  transition: all ease-in 300ms;

  a {
    font-size: 1.5rem;
    color: #1f1f1f;
    text-decoration: none;

    &.logo-anchor {
      height: 80%;
      width: 128px;

      img {
        height: 100%;
        width: 100%;
        padding: 5px 0;
      }
    }

    &.resume {
      -webkit-text-size-adjust: 100%;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: subpixel-antialiased;
      text-decoration: none;
      margin-left: auto;
      font-size: .85rem;
      padding: .5rem 1rem;
      appearance: button;
      background-color: hsl(var(--primary-color-hsl));
      background-image: none;
      border-radius: 4px;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      font-family: ITCAvantGardeStd-Bk, Arial, sans-serif;
      font-weight: 400;
      line-height: 20px;
      overflow: visible;
      text-align: center;
      text-transform: none;
      touch-action: manipulation;
      user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      position: relative;
      overflow: hidden;
      filter: invert(1) brightness(1.25);
      color: #06070d;

      &:before {
        position: absolute;
        content: "";
        pointer-events: none;
        transition: transform .1s linear;
        transform-origin: top right;
        top: 0;
        width: 100%;
        height: 100%;
        transform: translateX(calc(-50% + 13.5px)) skewX(-25.8deg);
        z-index: -1;
        background: linear-gradient(180deg,hsla(0,0%,100%,.5),hsla(0,0%,100%,0));
      }

      &:hover:before {
        transform: translateX(calc(-20%)) skewX(-25.8deg)
      }

      // &:active {
      //   box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
      //   outline: 0;
      //   box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
      //   transform: translate(2px, 2px);
      // }
    }
  }
}

.header-scrolled {
  background-color: #ffffff;
  box-shadow: 0px 1px 3px #ffffff7d;
}

#hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
  // from svgbackgrounds.com
  background-color: var(--bg-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='99' height='99' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23202020' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  position: relative;

  & > :not(.clock) {
    z-index: 10;
  }

  p:nth-child(1) {
    font-size: min(2.5rem, 10vw);
    color: rgb(247 247 247 / 75%);
    background-clip: text;
    background-image: var(--primary-text-bg);
    -webkit-text-fill-color: transparent;
  }

  p:nth-child(2) {
    font-size: min(1.5rem, 8vw);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    span {
      padding: 0 1rem;
      display: flex;
      align-items: center;
      color: rgb(255 255 255 / 50%);
      background-image: linear-gradient(180deg, rgb(255 255 255 / 65%), rgb(255 255 255 / 50%));
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    span:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.tech-stack {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;

  svg {
    width: 1.25rem;
    aspect-ratio: 1;
    transition: all 500ms;
  }

  &-name {
    display: none;
    margin-top: 0.5rem;
    color: #919191;
    position: absolute;
    top: 100%;
  }

  .mirror-shadow {
    --origin-x: 0px;
    --origin-y: 0px;
    --origin-scale: 0px;
    position: absolute;
    transform: translateX(var(--origin-x)) translateY(var(--origin-y)) scale(var(--origin-scale));
    // filter: blur(0.2px) contrast(2);
    opacity: 0.2;
    transition: all 500ms;
  }
}

#about {
  min-height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  place-content: center;
  background-color: var(--bg-color);

  figure {
    width: 100%;
    margin: 0;
    padding: 1rem;
    display: grid;
    place-items: center;

    img,
    .image-container {
      width: 170px;
      height: 170px;
      border-radius: 50%;
      position: relative;
    }

    img {
      filter: blur(0.4px);
    }

    .image-container {
      box-shadow: -3px 3px 3px rgb(0 0 0 / 34%);

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        background: linear-gradient(-126deg, rgb(0 0 0 / 21%) 0.19%, rgb(0 0 0 / 0%) 54.37%);
      }
    }
  }

  p {
    line-height: 1.7rem;
    font-family: "Hind", sans-serif;
    max-width: 650px;
    margin: 30px auto;
    font-weight: 300;
    font-size: 1.05rem;
    padding: 1rem;
    background-color: #303030;
    color: rgb(255 255 255 / 50%);
  }

  a {
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    color: hsl(var(--primary-color-hsl));
  }
}

#tech-stack {
  display: block;

  .stack-icons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4rem, 6rem));
    width: 100%;
    gap: 1rem;
    place-items: center;
    place-content: center;
  }

  .stack-icon {
    display: inline-block;
    width: 6rem;
    height: 6rem;

    i {
      font-size: 6rem;
    }
  }
}

section h3 {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 2rem 1rem 1rem 1rem;
  font-size: 2rem;
  font-weight: 300;
  color: rgb(247 247 247 / 75%);
  background-clip: text;
  background-image: var(--primary-text-bg);
  -webkit-text-fill-color: transparent;
}

footer {
  display: grid;
  place-content: center;
  place-items: center;
  padding: 1rem;
  background-color: var(--bg-color);

  a {
    text-decoration: none;
  }

  .socials {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-items: center;
    gap: 3rem;
    padding: 1rem;
    width: 100%;

    a {
      width: 24px;
      height: 24px;

      img {
        filter: invert(0.7) drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.2));
        transition: filter, transform ease-out 200ms;
      }

      &:hover img {
        filter: invert(0.7) drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.3));
        transform: scale(1.2);
      }
    }
  }

  .footer-end {
    a {
      font-size: 0.8rem;
      transition: filter ease-out 200ms;
      filter: invert(1);
    }
  }
}

.blur-tech {
  filter: blur(1px) grayscale(1);
}

@keyframes hue-rotate {
  @for $i from 0 through 100 {
    #{$i * 1%} {
      filter: hue-rotate(#{$i * 3.6} + deg);
    }
  }
}

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}
