// https://github.com/CovertGravy/recreational-projects/tree/main/clock
// codepen link = https://codepen.io/CovertGravy/pen/PoOGjMO

.clock {
  --degree: 0deg;
  --waves-play-state: running;
  --clock-width: 400px;
  width: var(--clock-width);
  aspect-ratio: 1;
  border-radius: 50%;
  border: 10px solid var(--bg-color);
  background-color: var(--bg-color);
  position: relative;
  transition-duration: 1s;
  box-shadow: -4px -4px 10px rgba(67, 67, 67, 0.5),
    inset 4px 4px 10px rgba(0, 0, 0, 0.5),
    inset -4px -4px 10px rgba(67, 67, 67, 0.5),
    4px 4px 10px rgba(0, 0, 0, 0.3);
  margin: 6rem;
  // filter: hue-rotate(var(--degree)) blur(1px);
  position: absolute;
  // filter: blur(1px);
  opacity: 0.3;
  scale: 1.25;
  // mix-blend-mode: color-dodge;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    // box-shadow:  4px 4px 6px rgb(255 255 255 / 10%),
    // 			 -3px -3px 5px rgb(255 255 255 / 10%);
    box-shadow: -4px -4px 10px rgba(67, 67, 67, 0.5),
      inset 4px 4px 10px rgba(0, 0, 0, 0.5),
      inset -4px -4px 10px rgba(67, 67, 67, 0.5),
      4px 4px 10px rgba(0, 0, 0, 0.3);
    animation: waves 10s alternate 0s infinite;
    animation-play-state: var(--waves-play-state);
  }

  .point {
    width: 4%;
    aspect-ratio: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    background-color: rgb(31, 28, 28);
    box-shadow: 0 0 10px 10px rgb(255 255 255 / 25%);
    filter: invert(1);
  }
}

.hand {
  position: absolute;
  bottom: 50%;
  left: 50%;
  // border-radius: 100% 100% 0 0;
  // box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 65%);
  transform: translateX(-50%) rotateZ(var(--degree));
  // transition: transform 0.2s cubic-bezier(.4,2.08,.55,.44);
  filter: invert(1);

  &::before {
    content: '';
    width: inherit;
    height: calc(var(--clock-width)/10);
    background-color: inherit;
    top: 100%;
    left: 0;
    position: absolute;
    box-shadow: inset 0 0 0px 4px rgb(0 0 0 / 50%);
  }

  &::after {
    content: attr(data-value);
    width: calc(var(--clock-width)/20);
    aspect-ratio: 1;
    background-color: transparent;
    color: white;
    top: calc((var(--height-diff) + var(--clock-width)/20 + var(--value-height)) * -1);
    transform: translate(-50%, -100%) rotate(calc(var(--degree)*-1));
    position: absolute;
    font-size: calc(var(--clock-width)/20);
    text-align: center;
    filter: invert(1);
    font-family: 'Orbitron', sans-serif;
  }

  &.second {
    --degree: 0;
    --height-diff: calc(var(--clock-width)/20);
    --value-height: calc(var(--clock-width)/8);
    width: 2px;
    height: calc(var(--clock-width)/2 - var(--height-diff));
    // background-color: black;
    background-color: rgb(0 0 0 / 50%);
    transform-origin: bottom;
  }

  &.minute {
    --degree: 0;
    --height-diff: calc(var(--clock-width)/8);
    --value-height: calc(var(--clock-width)/16);
    width: 5px;
    height: calc(var(--clock-width)/2 - var(--height-diff));
    // background-color: blueviolet;
    background-color: rgb(138 43 226 / 50%);
    // transform: rotate(60deg);
    transform-origin: bottom;
  }

  &.hour {
    --degree: 0;
    --height-diff: calc(var(--clock-width)/4);
    --value-height: 0px;
    width: 8px;
    height: calc(var(--clock-width)/2 - var(--height-diff));
    // background-color: red;
    background-color: rgb(255 0 0 / 50%);
    // transform: rotate(300deg);
    transform-origin: bottom;
  }

  // transition: all 100ms ease-in;
}

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}

@media only screen and (max-device-width: 1000px), (max-height: 745px) {
  .clock {
    display: none !important;
  }
}
